<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>入库申请</p>
      </div>
      <div class="addButton">
        <!-- v-if="right.apply" -->
        <el-button
          type="danger"
          @click="showAddDialog()"
        >新增入库申请</el-button>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="5">
          <!-- <el-col :span="8"><p class="searchItemTitle">商品分类</p></el-col> -->
          <el-col>
            <el-cascader
              v-model="selectCategoryOptions"
              :options="categoryOptions"
              :props="categoryProps"
              placeholder="请选择商品分类"
              clearable
            ></el-cascader>
          </el-col>
        </el-col>
        <el-col :span="5">
          <!-- <el-col :span="6">
            <p class="searchItemTitle">状态</p>
          </el-col>-->
          <el-col>
            <el-select v-model="searchForm.status" placeholder="请选择状态">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-col>
        <!-- <el-col :span="5">
          <el-col>
            <el-select v-model="searchForm.type" placeholder="请选择类型">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-col> -->
        <el-col :span="5">
          <el-input
            v-model="searchForm.holdCode"
            placeholder="请输入入库单编码"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="warehouseCode" width="160" label="入库单编码"></el-table-column>
        <!-- <el-table-column prop="applyFirmName" label="联系人"></el-table-column> -->
        <el-table-column prop="storageName" label="仓库"></el-table-column>
        <el-table-column prop="categoryName" label="商品"></el-table-column>
        <el-table-column prop="specificationModel" label="商品属性">
          <template slot-scope="scope">
            <span>{{
              scope.row.specificationModel | firstAttributeTransformate
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="quantity" label="数量"></el-table-column> -->
        <el-table-column prop="weight" label="重量">
          <template slot-scope="scope">
            <span>{{ scope.row.weight }}{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditStatus" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row | combinationStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="report" label="质检报告">
          <template slot-scope="scope">
            <!-- <img :src="scope.row.image" class="imgShow"> -->
            <span v-if="scope.row.report === null || scope.row.report === ''">---</span>
            <el-image
              v-else
              style="width: 100px; height: 100px"
              :src="scope.row.report"
              :preview-src-list="srcList"
              @click.stop="imgShow(scope.row.report)"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="auditNote" label="审核意见">
          <template slot-scope="scope">
            <span>{{ scope.row.auditNote ? scope.row.auditNote : '---' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="putWarehousTime" label="预计入库时间">
          <template slot-scope="scope">
            <span>{{ scope.row.putWarehousTime | DateFormateDay }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="applyHoldStatus" label="仓单状态">
          <template slot-scope="scope">
            <span>{{ scope.row.applyHoldStatus | applyHoldStatus }}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operateButtons">
              <el-button
                size="mini"
                style="width: 88px!important"
                @click="showDetails(scope.row)"
              >查看详情</el-button>
              <!-- <el-button @click="showSupplementaryDialog(scope.row)" type="warning" size="mini">补入库仓单</el-button> -->
              <!-- auditStatus：W待审核 F-----已驳回（有修改按钮） -->
              <el-button
                v-if="scope.row.auditStatus == 'W' || scope.row.auditStatus == 'F'"
                type="primary"
                size="mini"
                style="width: 88px!important"
                @click="amendDialog(scope.row)"
              >修改</el-button>
              <!-- auditStatus：T 且status: 3且userStatus：W-----待确定（有确定入库信息按钮） -->
              <el-button
                v-if="scope.row.auditStatus == 'T' && scope.row.status == 3 && scope.row.userStatus == 'W'"
                type="primary"
                size="mini"
                @click="confirmWarehouse(scope.row)"
              >确定入库信息</el-button>
              <!-- v-if="right.cancel" -->
              <el-button
                v-if="scope.row.auditStatus == 'W'"
                type="danger"
                size="mini"
                style="width: 88px!important"
                @click="cancelApply(scope.row)"
              >撤销</el-button>
              <!-- v-if="scope.row.applyHoldStatus != 'U'" -->
              <!-- <el-button
                v-if="scope.row.applyHoldStatus != 'U'"
                size="mini"
                @click="downloadApply(scope.row)"
              >下载存货凭证</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <!-- <a :href="img" target="_blank">123456</a> -->
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
    <amendDialog ref="amendDialog" @reLoad="getDatas()" />
    <!-- 新入库仓单 -->
    <newDialog ref="newDialog" @reLoad="getDatas()" />
    <!-- 补入库仓单 -->
    <!-- <supplementaryDialog ref="supplementaryDialog" @reLoad="getDatas()" /> -->
  </div>
</template>
<script>
/* global  protocolNJP http protocolNJP*/
import { mapGetters } from 'vuex'
import viewDialog from './viewDialog'
import newDialog from './newDialog'
import amendDialog from './amendDialog'
// import supplementaryDialog from './supplementaryDialog'

export default {
  data() {
    return {
      srcList: [],
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        commodityName: null,
        status: null,
        type: null,
        holdCode: null
      },
      statusOptions: [
        { value: 0, label: '待审核' },
        { value: 1, label: '已驳回' },
        { value: 2, label: '已撤销' },
        { value: 3, label: '已通过' },
        { value: 4, label: '待入库' },
        { value: 5, label: '待确定' },
        { value: 6, label: '已生成仓单' }
      ],
      typeOptions: [
        { value: 0, label: '申请入库' },
        { value: 1, label: '补入库' },
        { value: 2, label: '增发' }
      ],
      right: {
        apply: false,
        cancel: false
      },
      currentAuditStatus: null,
      selectApplyHold: null,
      selectStatus: null,
      selectUserStatus: null,
      selectCategoryOptions: [],
      categoryProps: {
        label: 'className',
        value: 'classId',
        checkStrictly: false
      },
      categoryOptions: []
    }
  },
  computed: {
    ...mapGetters(['sessionInfoGetter', 'isLabelGetter'])
  },
  components: {
    viewDialog,
    newDialog,
    amendDialog
    // supplementaryDialog
  },
  mounted() {
    this.getDatas()
    this.getCommodityCategory()
    // this.getRights()
  },
  methods: {
    imgShow(url) {
      this.srcList = []
      this.srcList.push(url)
    },
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.apply = this.isHasRight('tc-trade-addApplyWarehous')
          this.right.cancel = this.isHasRight('tc-trade-backoutApply')
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getDatas()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getDatas()
    },
    resetSearch() {
      this.searchForm = {
        commodityName: null,
        status: null,
        type: null,
        holdCode: null
      }
      this.selectCategoryOptions = []
      this.getDatas()
    },
    // 首页商品种类查询
    getCommodityCategory() {
      http.getRes(protocolNJP.param_getGoodsClassTree).then((response) => {
        const { code, message, value } = response.data
        if (code == 0) {
          const categoryArr = value
          this.categoryOptions = this.clearChildren(categoryArr)
        } else {
          this.categoryOptions = []
          this.$EL_MESSAGE(message)
        }
      })
    },
    clearChildren(Arr) {
      Arr.forEach((element) => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element['children']
          } else {
            this.clearChildren(element.children)
          }
        }
      })
      return Arr
    },
    getDatas() {
      // auditStatus => currentAuditStatus
      // applyHoldStatus => selectApplyHold
      // status => selectStatus
      // userStatus => selectUserStatus
      if (this.searchForm.status == '0') { // auditStatus：W -----待审核（有修改、撤销按钮）
        this.currentAuditStatus = 'W'
        this.selectApplyHold = null
        this.selectStatus = null
        this.selectUserStatus = null
      } else if (this.searchForm.status == '1') { // auditStatus： F-----已驳回
        this.currentAuditStatus = 'F'
        this.selectApplyHold = null
        this.selectStatus = null
        this.selectUserStatus = null
      } else if (this.searchForm.status == '2') { // applyHoldStatus：U-----已撤销
        this.currentAuditStatus = null
        this.selectApplyHold = 'U'
        this.selectStatus = null
        this.selectUserStatus = null
      } else if (this.searchForm.status == '3') { // auditStatus：T且status为1------已通过
        this.currentAuditStatus = 'T'
        this.selectApplyHold = null
        this.selectStatus = '1'
        this.selectUserStatus = null
      } else if (this.searchForm.status == '4') { // auditStatus：T 且status：2 ----待入库
        this.currentAuditStatus = 'T'
        this.selectApplyHold = null
        this.selectStatus = '2'
        this.selectUserStatus = null
      } else if (this.searchForm.status == '5') { // auditStatus：T 且status: 3且userStatus：W-----待确定
        this.currentAuditStatus = 'T'
        this.selectApplyHold = null
        this.selectStatus = '3'
        this.selectUserStatus = 'W'
      } else if (this.searchForm.status == '6') { // userStatus：T ------已生成仓单
        this.currentAuditStatus = null
        this.selectApplyHold = null
        this.selectStatus = null
        this.selectUserStatus = 'T'
      } else {
        this.currentAuditStatus = null
        this.selectApplyHold = null
        this.selectStatus = null
        this.selectUserStatus = null
      }
      protocolNJP.param_searchDelivery.param.page = this.current - 1
      protocolNJP.param_searchDelivery.param.size = this.pageSize
      protocolNJP.param_searchDelivery.param.sort = [{ property: 'applyWarehouseId', direction: 'DESC' }]
      protocolNJP.param_searchDelivery.param.categoryId = this.selectCategoryOptions.length > 0 ? this.selectCategoryOptions[this.selectCategoryOptions.length - 1] : null
      protocolNJP.param_searchDelivery.param.warehouseCode = this.searchForm.holdCode
      protocolNJP.param_searchDelivery.param.auditStatus = this.currentAuditStatus
      protocolNJP.param_searchDelivery.param.applyHoldStatus = this.selectApplyHold
      protocolNJP.param_searchDelivery.param.status = this.selectStatus
      protocolNJP.param_searchDelivery.param.userStatus = this.selectUserStatus
      http.postFront(protocolNJP.param_searchDelivery).then((response) => {
        const { code, message, value } = response.data
        if (code == 0) {
          this.tableDatas = value.content
          this.totalPage = value.totalElements
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    showAddDialog() {
      this.$refs.newDialog.showDialog()
    },
    amendDialog(row) {
      this.$refs.amendDialog.showDialog(row)
    },
    showSupplementaryDialog(row) {
      this.$refs.supplementaryDialog.showDialog(row)
    },
    showDetails(row) {
      this.$refs.viewDialog.showDialog(row)
    },
    // 确认入库
    confirmWarehouse(row) {
      this.$confirm('是否确定入库信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // applyWarehousId 入库单编号
          protocolNJP.param_userConfirm.param.applyWarehouseId = row.applyWarehouseId
          http.postFront(protocolNJP.param_userConfirm).then((response) => {
            const { code, message } = response.data
            if (code == 0) {
              this.$EL_MESSAGE(message)
              this.$router.push('/warehouseCenter/myPosition')
              this.$store.commit('setIsLabelGetter', 'id2')
              sessionStorage.setItem('isFlag', this.isLabelGetter)
              // this.getDatas()
            } else {
              this.$EL_MESSAGE(message)
            }
          })
        })
        .catch(() => {})
    },
    // 撤销入库申请
    cancelApply(row) {
      this.$confirm('请确认是否撤销该入库申请？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // applyWarehousId 入库单编号
          protocolNJP.param_deleteWarehouse.param.applyWarehouseId = row.applyWarehouseId
          http.postFront(protocolNJP.param_deleteWarehouse).then((response) => {
            const { code, message } = response.data
            if (code == 0) {
              this.$EL_MESSAGE(message)
              this.getDatas()
            } else {
              this.$EL_MESSAGE(message)
            }
          })
        })
        .catch(() => {})
    },
    // 下载存货凭证
    downloadApply() {
      this.$EL_MESSAGE('下载凭证暂不可用')
      // var alink = document.createElement('a')

      // alink.href = this.img
      // alink.download = 'pic' // 图片名

      // alink.click()
      // protocolNJP.param_getContentTitleList.param = {
      //   columnId: window.c.downloadFile,
      //   page: 0,
      //   size: 10
      // }
      // http.getRes(protocolNJP.param_getContentTitleList).then((response) => {
      //   const { code, message, value } = response.data
      //   if (code == 0) {
      //     this.uploadFileDatas = value.content
      //   } else {
      //     this.$EL_MESSAGE(message)
      //   }
      // })
    }
  }
}
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
  margin-bottom: 20px;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.searchList {
  margin: 10px 0;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}

.subPage .pagination {
  margin-top: 15px;
}
.operateButtons button {
  display: block;
  margin: 5px auto;
}
.searchItemTitle {
  line-height: 40px;
}
</style>
