<template>
  <el-dialog
    v-dialogDrag
    title="入库单详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <!-- <div class="auditCon">
      <p class="auditNote">审核意见：{{ form.auditNote }}</p>
    </div> -->
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">入库单编码：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.warehouseCode }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.storageName? form.storageName :'--' }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.categoryName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col v-for="(item,index) in nameArrays" :key="index" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">{{ item.name }}：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.value }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col v-for="(item,index) of form.specificationsName" :key="index" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">产地：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.placeOrigin }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">品牌：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.brand }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col v-if="form.quantity" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.weight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">磅差：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.poundsPoor }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col> -->

        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产日期：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.outWarehousDay | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col> -->

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">预计入库时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.putWarehousTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col v-if="form.type==1||form.type==2" :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">持仓ID：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.holdId }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系人：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.userName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系电话：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.userPhone }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form | combinationStatus }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">备注(批次)：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.note }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">图片：</p>
            </el-col>
            <el-col :span="16">
              <img v-if="form.image !== '--'" class="proPictureImg" :src="form.image">
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col> -->
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
/* global  protocolFwd http */
export default {
  data() {
    return {
      dialogVisible: false,
      specificationModel: {},
      nameArrays: [],
      form: {
        // enterpriseName: null, // 交易商名称
        // variety: null, // 商品品种
        // commodity: null, // 品名
        // specificationsName: null, // 规格
        // package: null, // 包装方式
        // grade: null, // 品级
        // specifications: null, // 包装规格
        // quantity: null, // 数量
        // weight: null, // 重量
        // manufacturer: null, // 生产厂家
        // batch: null, // 批次
        // outWarehousDay: null, // 生产日期
        // firmName: null, // 联系人
        // firmPhone: null, // 联系电话
        // name: null, // 交收仓库
        // warehousDay: null, // 入库日期
        // status: null, // 状态
        // type: null, // 类型
        // note: null // 备注
      }
    }
  },
  methods: {
    showDialog(row) {
      this.form = row
      this.nameArrays = this.NameValueTransformate(row.specificationModel)
      this.dialogVisible = true
    },
    NameValueTransformate(obj) {
      if (obj != null) {
        const nameArray = []
        const newObj = JSON.parse(obj)
        const newObjKey = Object.keys(newObj)
        const newObjValue = Object.values(newObj)
        if (newObjKey.length != 0) {
          for (let i = 0; i < newObjKey.length; i++) {
            const obj1 = {}
            obj1.name = newObjKey[i]
            obj1.value = newObjValue[i]
            nameArray.push(obj1)
          }
          return nameArray
        }
      }
    },
    getDetails(id) {
      protocolFwd.param_queryFirmAppplyHold.param.applyHoldId = id
      http
        .postFront(protocolFwd.param_queryFirmAppplyHold)
        .then(response => {
          const { code, message, value } = response.data
          if (code == 0) {
            if (value) {
              value.content[0].specificationsName = this.attributeTrans(value.content[0].specificationsName)
              this.form = value.content[0]
              for (const i in this.form) {
                if (this.form[i] === null || this.form[i] === '') {
                  this.form[i] = '--'
                }
              }
            }
          } else {
            this.$EL_MESSAGE(message)
          }
        })
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
  width: 120px;
  height: 120px;
  margin-top: 15px;
}
.auditCon{
  position: absolute;
  left:20%;
  top:20px;
}
</style>
