<template>
  <el-dialog
    v-dialogDrag
    title="入库申请"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div>
      <el-form ref="form" :rules="rules" :model="form" label-position="left">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商品名称" prop="commodityId" label-width="120px">
              <el-cascader
                ref="cascader"
                v-model="form.commodityId"
                class="width-100"
                :options="categoryOptions"
                :props="categoryProps"
                :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col v-for="(item,index) of gradeIdOptions" :key="index" :span="12">
            <el-form-item :label="item.name" prop="specificationsName" label-width="120px">
              <el-select v-model="form.specificationsName[index]" class="width-100">
                <el-option
                  v-for="items in item.children"
                  :key="items.id"
                  :label="items.value"
                  :value="item.value+','+items.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer" label-width="120px">
              <el-select v-model="form.manufacturer" class="width-100" clearable>
                <el-option
                  v-for="(item,index) in manufacturerList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="产地" prop="placeOrigin" label-width="120px">
              <el-select v-model="form.placeOrigin" class="width-100" clearable>
                <el-option
                  v-for="(item,index) in placeOriginList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="品牌" prop="brand" label-width="120px">
              <el-select v-model="form.brand" class="width-100" clearable>
                <el-option
                  v-for="(item,index) in brandList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="仓库" prop="storageId" label-width="120px">
              <el-select v-model="form.storageId" class="width-100">
                <el-option
                  v-for="item in storageIdOptions"
                  :key="item.storageId"
                  :label="item.storageName"
                  :value="item.storageId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity" label-width="120px">
              <el-input v-model.number="form.quantity"></el-input>
            </el-form-item>
            <!-- -->
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="重量" prop="weight" label-width="120px">
              <el-input v-model="form.weight">
                <template slot="append">{{ selectWeightUnit.name }}</template>
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="重量" prop="weight" label-width="120px">
              <el-input v-model="form.weight" class="weightPosition"></el-input>
            </el-form-item>
            <div class="weightUnitIdPosition">
              <el-form-item prop="weightUnitId">
                <el-select
                  v-model="form.weightUnitId"
                  placeholder="单位"
                >
                  <el-option
                    v-for="items in weightUnitOptions"
                    :key="items.unitId"
                    :label="items.unit"
                    :value="items.unitId"
                  ></el-option>
                </el-select>
              <!-- <el-input v-model="form.unit"></el-input> -->
              </el-form-item>

            </div>

          </el-col>

          <!-- <el-col :span="12">
            <el-form-item label="重量单位" prop="weightUnitId" label-width="120px">
              <el-select
                v-model="form.weightUnitId"
                class="width-100"
                placeholder="请先选择仓库，再选择重量单位"
              >
                <el-option
                  v-for="items in weightUnitOptions"
                  :key="items.unitId"
                  :label="items.name"
                  :value="items.unitId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="12">
            <el-form-item label="磅差" prop="poundsPoor" label-width="120px">
              <el-input v-model="form.poundsPoor">
                <template slot="prepend">±</template>
                <template slot="append">{{ selectWeightUnit.name }}</template>
              </el-input>
            </el-form-item>
          </el-col> -->

          <!-- <el-col :span="12">
            <el-form-item label="厂家" prop="manufacturer" label-width="120px">
              <el-input v-model="form.manufacturer"></el-input>
            </el-form-item>
          </el-col> -->

          <!-- <el-col :span="12">
            <el-form-item label="生产日期" prop="outWarehousDay" label-width="120px">
              <el-date-picker
                v-model="form.outWarehousDay"
                class="width-100"
                type="date"
                value-format="timestamp"
              ></el-date-picker>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item label="预计入库日期" prop="putWarehousTime" label-width="120px">
              <!-- <el-tooltip effect="dark" content="入库后检测需要2-3天" placement="top-end"> -->
              <el-date-picker
                v-model="form.putWarehousTime"
                class="width-100"
                type="date"
                value-format="timestamp"
                :picker-options="putWarehousTimePicker"
              ></el-date-picker>
              <!-- </el-tooltip> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="firmName" label-width="120px">
              <el-input v-model="form.firmName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="firmPhone" label-width="120px">
              <el-input v-model="form.firmPhone"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注(批次)" prop="note" label-width="120px">
              <el-input v-model="form.note"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="商品图片" prop="img" label-width="120px">
              <el-upload
                class="avatar-uploader"
                :action="actionUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :data="postData"
              >
                <img v-if="form.img" :src="form.img" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" :loading="isAddLoading" @click="confirmAddProduct()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
/* global  protocolNJP http protocolNJP*/
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/g
      if (value) {
        if (value == '') {
          callback(new Error('请输入数量'))
        } else if (!reg.test(value)) {
          return callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      } else {
        callback()
      }
      // if (value == "") {
      //   callback(new Error("请输入数量"));
      // } else if (!reg.test(value)) {
      //   return callback(new Error("请输入正整数"));
      // } else {
      //   callback();
      // }
    }
    var checkPutHoldWeight = (rule, value, callback) => {
      const reg = /^\d+(\.\d{0,2})?$/
      if (value == '') {
        return callback(new Error('请输入重量'))
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error('重量必须是正数且不能超过两位小数'))
      } else {
        callback()
      }
    }
    // var checkPoundsPoor = (rule, value, callback) => {
    //   const reg = /^\d+(\.\d{0,2})?$/
    //   if (value == '') {
    //     return callback(new Error('请输入磅差'))
    //   } else if (!reg.test(value)) {
    //     return callback(new Error('磅差必须是数字且不能超过两位小数'))
    //   } else {
    //     callback()
    //   }
    // }
    // var checkPutWarehousDay = (rule, value, callback) => {
    //   const currentTime = new Date()
    //   const yearTime = currentTime.getFullYear()
    //   let monTime = currentTime.getMonth() + 1
    //   let dayTime = currentTime.getDate()
    //   if (monTime < 10) {
    //     monTime = '0' + monTime
    //   }
    //   if (dayTime < 10) {
    //     dayTime = '0' + dayTime
    //   }
    //   const str = `${yearTime}${monTime}${dayTime}`
    //   const values = new Date(Number(value))
    //   const yearTimes = values.getFullYear()
    //   let monTimes = values.getMonth() + 1
    //   let dayTimes = values.getDate()
    //   if (monTimes < 10) {
    //     monTimes = '0' + monTimes
    //   }
    //   if (dayTimes < 10) {
    //     dayTimes = '0' + dayTimes
    //   }
    //   const newValue = `${yearTimes}${monTimes}${dayTimes}`
    //   if (value == '') {
    //     callback(new Error('请选择入库时间'))
    //   } else if (Number(newValue) < Number(str)) {
    //     return callback(new Error('入库时间只能选择今天及以后'))
    //   } else {
    //     callback()
    //   }
    // }
    var checkSpecName = (rule, value, callback) => {
      // console.log(
      //   this.gradeIdOptions.length != 0 &&
      //     value.length != this.gradeIdOptions.length
      // )
      if (
        this.gradeIdOptions.length != 0 &&
        value.length != this.gradeIdOptions.length
      ) {
        return callback(new Error('请选择商品属性'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {
        type: null, // 0:申请入库 1:补入库
        commodityId: null, // 商品品种//[申请]
        batch: null, // 批次
        firmName: null, // 联系人
        firmPhone: null, // 联系电话
        manufacturer: null, // 厂家 //[申请]
        outWarehousDay: null, // 生产日期
        quantity: null, // 数量
        specificationsId: null, //
        specificationsName: [], // 属性
        storageId: null, // 仓库[申请]
        putWarehousTime: null, // 入库日期[申请]
        weight: null, // 重量//[申请]
        applyHoldId: null, // 申请入库id(修改时传)
        note: null, // 申请，补入
        poundsPoor: null, // [申请]
        weightUnitId: null, // [申请]
        img: null, // 商品图片
        placeOrigin: null, // 产地
        brand: null // 品牌
      },
      brandList: [],
      manufacturerList: [],
      placeOriginList: [],
      actionUrl: window.g.APP_URL_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      rules: {
        commodityId: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        specificationsName: [
          { required: false, message: '请选择商品属性', trigger: 'change' },
          { validator: checkSpecName, trigger: 'blur' }
        ],
        firmName: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        firmPhone: [
          { required: true, message: '请输入联系电话', trigger: 'change' }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ],
        // manufacturer: [
        //   { required: true, message: "请输入生产厂家", trigger: "change" }
        // ],
        // outWarehousDay: [
        //   { required: true, message: '请选择生产日期', trigger: 'change' }
        // ],
        quantity: [
          { required: false, message: '请输入数量', trigger: 'change' },
          { validator: checkAmount, trigger: 'blur' }
        ],
        storageId: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        // placeOrigin: [
        //   { required: true, message: '请选择产地', trigger: 'change' }
        // ],
        putWarehousTime: [
          { required: true, message: '请选择预计入库时间', trigger: 'change' }
          // { validator: checkPutWarehousDay, trigger: "blur" }
        ],
        weight: [{ required: true, message: '请输入重量', trigger: 'change' },
          {
            validator: checkPutHoldWeight, trigger: 'change'
          }],
        weightUnitId: [
          { required: true, message: '请选择重量单位', trigger: 'blur' }
        ],
        // poundsPoor: [
        //   { required: true, message: '请输入磅差', trigger: 'change' },
        //   { validator: checkPoundsPoor, trigger: 'change' }
        // ],
        img: [{ required: false, message: '请上传图片', trigger: 'change' }]
        // note: [{ required: true, message: "请输入备注", trigger: "change" }]
      },
      putWarehousTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      areaBrand: {},
      categoryProps: {
        label: 'className',
        value: 'classId'
      },
      gradeIdProps: {
        label: 'value',
        value: 'value'
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      storageIdOptions: [],
      isAddLoading: false,
      newCommodityId: null,
      weightUnitOptions: [],
      selectWeightUnit: {},
      unit: ''
    }
  },
  watch: {
    'form.commodityId': {
      handler(val, oldVal) {
        this.gradeIdOptions = []
        this.form.specificationsName = []
        this.getGradeIdOptions()
      }
    }
    // 'form.weightUnitId': {
    //   handler(val, oldVal) {
    //     const that = this
    //     for (let i = 0; i < this.weightUnitOptions.length; i++) {
    //       if (val == that.weightUnitOptions[i].unitId) {
    //         that.selectWeightUnit = that.weightUnitOptions[i]
    //       }
    //     }
    //   }
    // }
  },
  methods: {
    // 当前选中仓库
    // unitChange(e) {
    //   this.weightUnitOptions = []
    //   this.form.weightUnitId = ''
    //   this.$refs['form'].clearValidate(['weightUnitId'])
    //   this.selectWeightUnit.name = ''
    //   protocolNJP.param_findWeightUnit.param.storageId = e
    //   protocolNJP.param_findWeightUnit.param.sort = [
    //     { property: 'createTime', direction: 'DESC' }
    //   ]
    //   http.postFront(protocolNJP.param_findWeightUnit).then(response => {
    //     const { value, code } = response.data
    //     if (code == 0) {
    //       this.weightUnitOptions = value.content
    //     }
    //   })
    // },
    // 单位查询
    unitIdChange() {
      protocolNJP.param_findUnit.param.sort = [
        { property: 'createTime', direction: 'DESC' }
      ]
      http.postFront(protocolNJP.param_findUnit).then(response => {
        const { value, code } = response.data
        if (code == 0) {
          this.weightUnitOptions = value
        }
      })
    },
    showDialog() {
      // const _cascader = this.$refs.cascader
      // if (_cascader) {
      //   _cascader.$refs.panel.checkedValue = []
      //   _cascader.$refs.panel.activePath = []
      //   _cascader.$refs.panel.syncActivePath()
      // }
      this.dialogVisible = true
      // 查询商品分类
      this.getCommodityCategory()
      // 查询仓库字典
      this.getStorageIdOptions()
      // 查询单位
      this.unitIdChange()
      // this.getfindWeightUnit()
      // 查询企业经营信息
      // this.getInformation()
    },
    queryMe(companyName, list) {
      console.log(companyName.indexOf(','), '2525')
      if (companyName && companyName.indexOf(',') != -1) {
        const newCompany = companyName.split(',')
        for (let i = 0; i < newCompany.length; i++) {
          const obj = { 'name': newCompany[i] }
          list.push(obj)
        }
      } else if (companyName && companyName.indexOf(',') == -1) {
        const obj = { 'name': companyName }
        list.push(obj)
        console.log(list, '789')
      } else {
        list = []
      }
    },
    getInformation() {
      protocolNJP.param_getInformation.param.userId = sessionStorage.getItem('userId')
      http.postFront(protocolNJP.param_getInformation).then(response => {
        const { code, message, value } = response.data
        if (code == 0) {
          if (value) {
            this.queryMe(value.manufacturer, this.manufacturerList)
            this.queryMe(value.brand, this.brandList)
            this.queryMe(value.placeOrigin, this.placeOriginList)
          }
          if (value == null) {
            this.brandList = []
            this.manufacturerList = []
            this.placeOriginList = []
          }
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    // 查询商品分类
    getCommodityCategory() {
      http.getRes(protocolNJP.param_getGoodsClassTree).then(response => {
        const { code, message, value } = response.data
        if (code == 0) {
          this.deleteCategoryOptionsLast(value)
          this.categoryOptions = value
        } else {
          this.$EL_MESSAGE(message)
        }
      })
      // http.getRes(protocolNJP.param_queryVarieties).then(response => {
      //   const { code, message, value } = response.data
      //   if (code == 0) {
      //     this.deleteCategoryOptionsLast(value)
      //     this.categoryOptions = value
      //   } else {
      //     this.$EL_MESSAGE(message)
      //   }
      // })
    },
    handleAvatarSuccess(res, file) {
      this.form.img = res.value
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!testmsg) {
        this.$EL_MESSAGE.error('上传图片格式不对!')
        return
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error('上传图片大小不能超过 2MB!')
      }
      return testmsg && isLt2M
    },
    // 商品种类单位查询
    getfindWeightUnit() {
      http.postFront(protocolNJP.param_findWeightUnit).then(response => {
        const { code, message } = response.data
        if (code == 0) {
          // this.weightUnitOptions = value.content;
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children)
        } else {
          delete item.children
        }
      }
    },
    // // 首页商品品级查询
    getGradeIdOptions() {
      console.log('选择属性')
      if (Array.isArray(this.form.commodityId)) {
        this.newCommodityId = this.form.commodityId[
          this.form.commodityId.length - 1
        ]
      } else {
        this.newCommodityId = this.form.commodityId
      }
      protocolNJP.param_queryTemplateGroup.param.goodsGroupClassId = this.newCommodityId
      http.postFront(protocolNJP.param_queryTemplateGroup).then(response => {
        const { code, message, value } = response.data
        if (code == 0) {
          if (value.length != 0) {
            const goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate)
            for (let i = 0; i < goodsGroupCommonSpec.length; i++) {
              goodsGroupCommonSpec[i].id = value[0].id
              goodsGroupCommonSpec[i].value = goodsGroupCommonSpec[i].name
              goodsGroupCommonSpec[i].children = goodsGroupCommonSpec[i].content
            }
            this.gradeIdOptions = goodsGroupCommonSpec
          } else {
            this.gradeIdOptions = []
          }
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    // 查询仓库字典
    getStorageIdOptions() {
      http.postFront(protocolNJP.param_getStorage).then(response => {
        const { code, message, value } = response.data
        if (code == 0) {
          this.storageIdOptions = value
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    confirmAddProduct() {
      console.log(this.weightUnitOptions, '12312')
      this.$refs['form'].validate(valid => {
        if (valid) {
          let specificationsNames = {} // 对象
          //  console.log(this.form.specificationsName)
          if (this.form.specificationsName.length != 0) {
            for (let i = 0; i < this.form.specificationsName.length; i++) {
              const currentObj = {}
              const str = this.form.specificationsName[i]

              const str1 = str.split(',')
              currentObj[str1[0]] = str1[1]

              specificationsNames = Object.assign(
                specificationsNames,
                currentObj
              )
            }
          }
          if (this.weightUnitOptions.length != 0) {
            console.log('45')
            this.unit = ''
            for (let i = 0; i < this.weightUnitOptions.length; i++) {
              if (this.weightUnitOptions[i]['unitId'] === this.form.weightUnitId) {
                this.unit = this.weightUnitOptions[i]['unit']
              }
            }
          }
          if (JSON.stringify(specificationsNames) == '{}') {
            specificationsNames = null
          }
          // protocolNJP.param_addApplyWarehous.param = this.form;
          // protocolNJP.param_addWarehouse.param.manufacturer = this.form.manufacturer
          // protocolNJP.param_addWarehouse.param.placeOrigin = this.form.placeOrigin
          // protocolNJP.param_addWarehouse.param.brand = this.form.brand
          // protocolNJP.param_addWarehouse.param.outWarehousDay = this.form.outWarehousDay
          protocolNJP.param_addWarehouse.param.quantity = this.form.quantity
          // protocolNJP.param_addWarehouse.param.poundsPoor = this.form.poundsPoor
          // protocolNJP.param_addWarehouse.param.applyWarehouseId = this.form.applyHoldId
          protocolNJP.param_addWarehouse.param.type = 0
          protocolNJP.param_addWarehouse.param.userName = this.form.firmName
          protocolNJP.param_addWarehouse.param.userPhone = this.form.firmPhone
          protocolNJP.param_addWarehouse.param.storageId = this.form.storageId
          // protocolNJP.param_addWarehouse.param.img = this.form.img
          protocolNJP.param_addWarehouse.param.putWarehousTime = this.form.putWarehousTime
          protocolNJP.param_addWarehouse.param.weight = this.form.weight
          protocolNJP.param_addWarehouse.param.note = this.form.note
          protocolNJP.param_addWarehouse.param.weightUnitId = this.form.weightUnitId
          protocolNJP.param_addWarehouse.param.categoryId = this.newCommodityId
          protocolNJP.param_addWarehouse.param.specificationModel = specificationsNames
          protocolNJP.param_addWarehouse.param.unit = this.unit
          this.isAddLoading = true
          http
            .postFront(protocolNJP.param_addWarehouse)
            .then(response => {
              const { code, message } = response.data
              this.isAddLoading = false
              if (code == 0) {
                this.dialogVisible = false
                this.$EL_MESSAGE('提交成功！待后台审核成功后方可入库')
                this.handleClose()
                this.$refs['form'].resetFields()
                this.$emit('reLoad')
              } else {
                this.$EL_MESSAGE(message)
              }
            })
            .catch(() => {
              this.isAddLoading = false
            })
        }
      })
    },
    handleClose() {
      this.form = {
        type: null, // 0:申请入库 1:补入库
        commodityId: null, // 商品品种//[申请]
        batch: null, // 批次
        firmName: null, // 联系人
        firmPhone: null, // 联系电话
        manufacturer: null, // 厂家 //[申请]
        outWarehousDay: null, // 生产日期
        quantity: null, // 数量
        specificationsId: null, //
        specificationsName: [], // 属性
        storageId: null, // 仓库[申请]
        putWarehousTime: null, // 入库日期[申请]
        weight: null, // 重量//[申请]
        applyHoldId: null, // 申请入库id(修改时传)
        note: null, // 申请，补入
        poundsPoor: null, // [申请]
        weightUnitId: null, // [申请]
        img: null, // 商品图片
        placeOrigin: null, // 产地
        brand: null // 品牌
      }
      this.unit = ''
      this.brandList = []
      this.manufacturerList = []
      this.placeOriginList = []
      this.selectWeightUnit = {}
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
      this.weightUnitOptions = []
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="scss">
/deep/.weightPosition{
  // margin-right: 300px!important;
  width: 72%!important;
  .el-input__inner{
    border-radius: 4px 0 0 4px;
  }
}
/deep/.weightUnitIdPosition{
  float:right;
  width: 20%!important;
  margin-top: -62px;
  .el-input__inner{
    border-radius: 0 4px 4px 0;
  }
}
/deep/ .el-form-item__content{
  // margin-left: 112px!important;
}
</style>
